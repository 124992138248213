import React from 'react';
import { Box } from '@material-ui/core';
import pricingIcon from '../../resources/pricing-icon.svg';
import { monthData } from '../../contants';
import { consultationPrice } from '../../contants';

const PricingCard = () => {
  const d = new Date();
  let monthName = monthData[d.getMonth()];
  return (
    <>
      <Box className='top-banner'>
        <p className='banner-text'>
          Use coupon <b>50Percent</b> to get a <b>50% discount</b> on a consultation during {monthName}
        </p>
      </Box>
      <Box
        width='100%'
        height={{ xs: '520px', lg: '455px' }}
        display='flex'
        flexDirection='row'
        sx={{ p: 2 }}
        bgcolor='#ffffff'
        borderRadius='20px'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        boxShadow='4px 8px 20px rgba(229, 233, 246, 0.79)'>
        <Box
          bgcolor='#1085F0'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          color='#ffffff'
          borderRadius='20px'
          width={{ xs: '100%', md: '30%' }}
          height={{ xs: '30%', sm: '50%', lg: '100%' }}>
          <Box component={'span'} sx={{ fontSize: { xs: '2em', lg: '5em' }, fontWeight: '600' }}>
            ${consultationPrice}
          </Box>
          <Box component={'span'} sx={{ fontSize: { xs: '1em', lg: '1.5em' }, fontWeight: '400' }}>
            Flat fee for all specialties!
          </Box>
        </Box>
        <Box
          height={{ xs: '70%', sm: '50%', md: '85%', xl: '80%' }}
          display='flex'
          flexDirection='column'
          justifyContent={{ xs: 'center', lg: 'start' }}
          alignItems={{ xs: 'start', lg: 'start' }}
          width={{ xs: '100%', sm: '100%', md: '65%' }}>
          <Box
            width={{ xs: '24px', md: '40px', lg: '68px' }}
            height={{ xs: '24px', md: '40px', lg: '68px' }}
            mb={{ xs: 2, lg: 3, xl: 5 }}
            mt={1}>
            <img alt='pricing icon' src={pricingIcon} style={{ width: '100%', height: '100%' }} />
          </Box>
          <Box
            component={'span'}
            sx={{
              fontSize: { xs: '1em', md: '1.75em' },
              fontWeight: '600',
              color: '#1085F0',
              textAlign: 'start',
              mb: { xs: 1, md: 3 },
            }}>
            High-Quality Online Medical Consultations at Competitive Prices.
          </Box>
          <Box
            sx={{
              textAlign: 'start',
              m: 0,
              p: 0,
              fontSize: { xs: '0.8em', md: '1.2em' },
            }}>
            <ul
              style={{
                margin: '16px',
                padding: 0,
                fontSize: '1em',
                color: '#505565',
                fontWeight: '400',
              }}>
              <li style={{ margin: '8px 0px' }}>Skip the Commute, Save Time, and Get the Care You Need.</li>
              <li style={{ margin: '8px 0px' }}>
                No More Waiting Rooms: Get Fast, Convenient Online Medical Consultations.
              </li>
              <li style={{ margin: '8px 0px' }}>
                Expert Medical Advice: Receive Written Consultation Report. For a Premium Fee, Video Consultation
                Available.
              </li>
              <li style={{ margin: '8px 0px' }}>
                <span style={{ fontWeight: '600', fontSize: '1.2em' }}>
                  Money back guarantee if you are not satisfied with our services!
                </span>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PricingCard;
