import React from 'react';
import { Box } from '@material-ui/core';
import '../../css/carousel.css';

const Carousel = (props) => {
  const { currentCard, setCurrentCard, markup, Reviews, arrow } = props;

  return (
    <Box width='100%' display='flex' flexDirection='column' justifyContent='space-between'>
      <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
        <Box className='arrow-btn-container'>
          {arrow && currentCard > 0 && (
            <button className='arrow-btn' onClick={() => setCurrentCard(currentCard - 1)}>
              &lt;
            </button>
          )}
        </Box>
        <Box width='90%'>{markup}</Box>
        {arrow && currentCard < Reviews.length - 1 && (
          <Box className='arrow-btn-container'>
            <button className='arrow-btn' onClick={() => setCurrentCard(currentCard + 1)}>
              &gt;
            </button>
          </Box>
        )}
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ mt: 4 }}>
        {Reviews.map((obj, ind) => (
          <Box
            className={`carousel-dot ${ind === currentCard && 'selected'}`}
            onClick={() => setCurrentCard(ind)}></Box>
        ))}
      </Box>
    </Box>
  );
};

export default Carousel;
