import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import useContentful from '../../useContentful';
import Carousel from './Carousel';
import patientIcon from '../../resources/patienticon.svg';
import '../../css/landingpage.css';

const PatientReviews = (props) => {
  const { mode } = props;
  const { getReviews } = useContentful();
  const [currentCard, setCurrentCard] = useState(0);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviews()
      .then((res) => {
        setReviews(res.clientReview);
      })
      .catch((error) => {
        console.error('Error fetching reviews:', error);
      });
  }, []);

  return (
    <Carousel
      markup={
        mode === 'mob' ? (
          <Box
            height='380px'
            width='100%'
            borderRadius='20px'
            className='patient-review-card shadow-interaction'
            display='flex'
            flexDirection='column'
            alignItems='space-between'
            justifyContent='space-between'
            sx={{ p: 4 }}>
            <Box component={'span'} className='patient-review-text'>
              {reviews[currentCard]?.fields?.patientReview}
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Box display='flex' flexDirection='column'>
                <span className='patient-name'>{reviews[currentCard]?.fields?.patientName}</span>
              </Box>
              <img src={patientIcon} alt='patient icon' height='26px' width='26px' />
            </Box>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center' gap={2}>
            <Box
              width='78%'
              borderRadius='20px'
              className='patient-review-card shadow-interaction'
              display='flex'
              flexDirection='column'
              alignItems='space-between'
              justifyContent='space-around'
              sx={{ p: 4 }}>
              <Box component={'span'} className='patient-review-text'>
                {reviews[currentCard]?.fields?.patientReview}
              </Box>
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='column'>
                  <span className='patient-name'>{reviews[currentCard]?.fields?.patientName}</span>
                </Box>
                <img alt='patient icon' src={patientIcon} height='25px' width='25px' />
              </Box>
            </Box>
          </Box>
        )
      }
      currentCard={currentCard}
      setCurrentCard={setCurrentCard}
      Reviews={reviews}
      arrow={mode === 'mob' ? false : true}
    />
  );
};

export default PatientReviews;
