import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const accountPost = (request) => {
  const url = 'user/v1/profile-request/' + request.id + '/';
  return (dispatch) => {
    dispatch(fetchAccountPostRequest());
    const requestData = {
      request_status: request.status,
    };

    if (request.specialty && request.specialty !== '1') {
      requestData['specialty'] = request.specialty.uuid;
    }

    authRequest(url, 'PATCH', requestData, request.token)
      .then((res) => {
        dispatch(fetchAccountPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchAccountPostFailure(error));
      });
  };
};

export const fetchAccountPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchAccountPostSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchAccountPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
