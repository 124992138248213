import React from 'react';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LandingPageHeader from '../components/LandingPageHeader';
import LandingPageSubHeader from '../components/LandingPageSubHeader';
import LandingPageFooter from '../components/LandingPageFooter';

const PageLayout = ({ children }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('xs') && theme.breakpoints.down('md'));
  return (
    <Grid xs={12} display='flex' container justifyContent='center' alignItems='center'>
      {!isTablet && <LandingPageSubHeader />}
      <LandingPageHeader />
      <Box mt={4}></Box>
      {children}
      <LandingPageFooter />
    </Grid>
  );
};

export default PageLayout;
