export const servicesList = [
  { name: 'Allergy and Immunology', path: '/allergy-and-immunology-online-consultation' },
  { name: 'Cardiology', path: '/cardiologist-online-consultation' },
  { name: 'Dermatology', path: '/dermatologist-online-consultation' },
  { name: 'Endocrinology', path: '/endocrinologist-online-consultation' },
  { name: 'Gastroenterology/Hepatology', path: '/gastroenterology-and-hepatology-online-consultation' },
  { name: 'Infectious Diseases', path: '/Infectious-disease-specialist-online-consultation' },
  { name: 'Internal Medicine', path: '/internal-medicine-physicians-online-consultation' },
  { name: 'Nephrology', path: '/nephrologist-online-consultation' },
  { name: 'Neurology', path: '/neurologist-online-consultation' },
  { name: 'Oncology/Hematology', path: '/oncologist-hematologist-online-consultation' },
  { name: 'Ophthalmology', path: '/eye-doctor-online-consultation' },
  { name: 'Pain and Rehabilitation', path: '/rehabilitation-specialist-online-consultation' },
  { name: 'Pulmonology/Critical Care', path: '/pulmonologist-online-consultation' },
  { name: 'Rheumatology', path: '/rheumatologist-online-consultation' },
];

export const mediaList = [
  { name: 'Blogs', path: '/blogs' },
  { name: 'Vlogs', path: '/vlogs' },
];
