import { createClient } from 'contentful';

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_CMS_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_CMS_ACCESS_TOKEN,
    host: process.env.REACT_APP_CONTENTFUL_CMS_HOST,
  });

  const getReviews = async () => {
    try {
      const entries = await client.getEntries({
        content_type: 'clientReviewSection',
      });
      return entries?.items[0].fields;
    } catch (error) {
      console.error('Message:', error.message);
    }
  };

  const getVlogVideos = async () => {
    try {
      const entries = await client.getEntries({
        content_type: 'vlogSection',
        select: 'fields',
      });
      return entries?.items[0].fields;
    } catch (error) {
      console.error('Message:', error.message);
    }
  };

  const getBlogs = async () => {
    try {
      const entries = await client.getEntries({
        content_type: 'blogSection',
        select: 'fields',
      });
      return entries?.items[0].fields;
    } catch (error) {
      console.error('Message:', error.message);
    }
  };

  return { getReviews, getVlogVideos, getBlogs };
};

export default useContentful;
